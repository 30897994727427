
import {defineComponent} from 'vue';
import SpiderForm from '@/components/spider/SpiderForm.vue';

export default defineComponent({
  name: 'SpiderDetailTabOverview',
  components: {
    SpiderForm,
  },
  setup() {
    return {};
  },
});
